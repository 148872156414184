import axios from 'axios';

const emailValidation = async (data) => {
  const config = {
    method: 'POST',
    url: `${process.env.REACT_APP_BASE_URL}/gs1/validation`,
    headers: {
      'content-type': 'application/json',
    },
    data,
  };

  try {
    const { data } = await axios(config);
    return data;
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};

export { emailValidation };
