import axios from 'axios';
import * as Sentry from '@sentry/react';

const calculate = async (data) => {
  const config = {
    method: 'POST',
    url: `${process.env.REACT_APP_BASE_URL}/products/calculate`,
    headers: {
      'content-type': 'application/json',
    },
    data,
  };

  try {
    const { data } = await axios(config);
    return {
      message: data?.message || '',
      success: data?.success,
      data: data?.data,
    };
  } catch (error) {
    Sentry.setContext('request', { config });
    Sentry.setContext('response', error.response.data);
    Sentry.captureMessage(config.url);
    return { success: false, data: error.response.data };
  }
};

export { calculate };
