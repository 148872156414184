import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/scss/theme.scss';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { TransactionProvider } from './contexts/TransactionContext';

import { Cart } from './pages/cart';
import { CartPromotion } from './pages/cartPromotion';
import { CartPublic } from './pages/cartPublic';
import Payment from './pages/payment';
import PaymentGroup from './pages/paymentGroup';
import Login from './pages/login';

Sentry.init({
  dsn: 'https://e8f1e2a0c72f45a18c2177c1f7861c8a@o1192043.ingest.us.sentry.io/4506898228510720',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  maxMessageLength: 10000,
  normalizeDepth: 10,
});

function App() {
  return (
    <TransactionProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={<CartPromotion />}
          />
          {/* <Route
            path='/'
            element={<Login />}
          />
          <Route
            path='/carrinho'
            element={<Cart />}
          /> */}
          {/* <Route
            path='/'
            element={<CartPublic />}
          /> */}
          <Route
            path='/pagamento'
            element={<Payment />}
          />
          <Route
            path='/pagamento_grupo'
            element={<PaymentGroup />}
          />
        </Routes>
      </BrowserRouter>
    </TransactionProvider>
  );
}

export default App;
