export default function Success({ paymentType, handleAction, t }) {
  const free = (
    <>
      <h1 className='text-success text-center'>{t('success.free.title')}</h1>
      <p>{t('success.free.message')}</p>
    </>
  );

  const paid = (
    <>
      <h1 className='text-success text-center'>{t('success.paid.title')}</h1>
      <p> {t('success.paid.message')}</p>
    </>
  );

  const options = {
    pix: paid,
    credit_card: paid,
    free: free,
  };
  return (
    <>
      {options[paymentType]}
      {/* <button
        className='btn btn-secondary'
        onClick={handleAction}
      >
        {t('success.home')}
      </button> */}
    </>
  );
}
