import { Row, Col } from 'reactstrap';

export default function PromoCode({
  promoCode,
  setPromoCode,
  handlePromoCode,
  t,
}) {
  return (
    <>
      <Row className='ps-3 pe-3 align-items-end'>
        <Col
          md={6}
          className='mb-2'
        >
          <label
            htmlFor='promo-code'
            className='form-label'
          >
            <strong>{t('promocode.fields.title')}</strong>
          </label>
          <input
            type='text'
            id='promo-code'
            className='form-control w-100'
            value={promoCode}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value?.toUpperCase();
              setPromoCode(newValue);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-2'
        >
          <button
            className='btn btn-secondary w-100'
            onClick={handlePromoCode}
          >
            {t('promocode.fields.apply')}
          </button>
        </Col>
      </Row>
    </>
  );
}
