export const Information = ({ t }) => {
  const paragraphs = t('event.description.paragraphs', { returnObjects: true });
  return (
    <>
      <h2 className='mt-3 mb-3 display-7'>{t('event.description.title')}</h2>
      {paragraphs.map((paragraph, index) => (
        <p
          key={index}
          className='mb-3'
        >
          {paragraph}
        </p>
      ))}
      {/* <div class='embed-responsive embed-responsive-16by9 mt-3'>
        <iframe
          src='https://www.youtube.com/embed/HT1ZlP7vdy8?si=DicEk2fYrR0wnnve'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen
        ></iframe>
      </div>
      <div class='embed-responsive embed-responsive-16by9 mt-3'>
        <iframe
          src='https://www.youtube.com/embed/PTplu3YFNlg?si=r-sWPXrBICaaOFtS'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen
        ></iframe>
      </div> */}
    </>
  );
};
