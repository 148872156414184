import axios from 'axios';

const isPixPaid = async (id) => {
  const config = {
    method: 'GET',
    url: `${process.env.REACT_APP_BASE_URL}/transactions/${id}`,
    headers: {
      'content-type': 'application/json',
    },
  };

  try {
    const { data } = await axios(config);
    return data;
  } catch (error) {
    const data = error?.response?.data || { success: false };
    return data;
  }
};

export { isPixPaid };
