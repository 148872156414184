import * as Sentry from '@sentry/react';
import { ACCOUNTID, CREDITCARD, TESTMODE } from '../helpers/constants';
import * as transactionService from './transaction';

const createPaymentToken = async (paymentType, cctoken, email = '') => {
  if (paymentType !== CREDITCARD) return { success: true, token: '' };

  try {
    const name = cctoken.name.split(' ');

    const firstName = name.shift();
    const lastName = name.pop();

    const expireAt = cctoken.expireAt.split('/');
    const month = expireAt.shift();
    const year = expireAt.pop();

    window.Iugu.setAccountID(ACCOUNTID);
    window.Iugu.setTestMode(TESTMODE);

    const creditCard = window.Iugu.CreditCard(
      cctoken.number,
      month,
      year,
      firstName,
      lastName,
      cctoken.code,
    );

    return new Promise(async (resolve) => {
      try {
        window.Iugu.createPaymentToken(creditCard, async (response) => {
          if (response.errors) {
            Sentry.setContext('createPaymentTokenResponse', { response });
            Sentry.captureMessage('createPaymentToken');
            console.log('createPaymentToken', response.errors);
            await transactionService.errorLog(email, JSON.stringify(response));
            resolve({
              success: false,
              token: '',
              message: 'Erro ao gerar pagamento, verifique os dados do cartão!',
            });
          } else {
            resolve({ success: true, token: response.id });
          }
        });
      } catch (error) {
        Sentry.setContext('error', { error });
        Sentry.captureMessage('createPaymentToken');
        console.log('createPaymentToken', error);
        await transactionService.errorLog(email, JSON.stringify(error));
        resolve({
          success: false,
          token: '',
          message: 'Erro ao gerar pagamento, verifique os dados do cartão!',
        });
      }
    });
  } catch (error) {
    Sentry.setContext('error', { error });
    Sentry.captureMessage('createPaymentToken');
    console.log('createPaymentToken', error);
    await transactionService.errorLog(email, JSON.stringify(error));
    return {
      success: false,
      token: '',
      message: 'Erro ao gerar pagamento, verifique os dados do cartão!',
    };
  }
};

export { createPaymentToken };
