import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

const Loading = ({ open, loadingMessage, ...props }) => {
  const [dimensions, setDimensions] = React.useState(getCurrentDimension());

  React.useEffect(() => {
    const updateDimension = () => {
      setDimensions(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);
    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [dimensions]);

  const styles = {
    window: {
      display: 'flex',
      width: 150,
      height: 100,
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 15,
      elevation: 20,
      shadowColor: '#52006A',
      flexDirection: 'column',
      boxShadow: '0px 0px 10px #3b3a3a',
    },
    text: {
      fontFamily: 'Verdana',
      color: '#404040',
    },
  };

  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box height={`${dimensions.height}px`} display="flex" justifyContent="center" alignItems="center">
        <Box style={styles.window}>
          <CircularProgress style={{ color: styles.text.color }} />
          <Box style={styles.text}>{loadingMessage}</Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default Loading;
