import { Row, Col } from 'reactstrap';
import {
  onDelete,
  alphabetMask,
  emailMask,
  mobileMask,
  badgeMask,
  cpfMask,
  cnpjMask,
} from '../../helpers/masks';
import { useEffect } from 'react';
import { cnpj } from 'cpf-cnpj-validator';

export const AttendeeGroup = ({
  index,
  attendees = [],
  fillAttendee,
  length,
  t,
  htmlId,
  cnpjLogin,
  cpfLogin,
  checkIfCpfIsRegistered,
  nextButtonControl,
}) => {
  const roles = t('attendee.fields.role.options', {
    returnObjects: true,
  });

  const specialitys = t('attendee.fields.speciality.options', {
    returnObjects: true,
  });
  const industries = t('attendee.fields.industry.options', {
    returnObjects: true,
  });

  const states = t('attendee.fields.state.options', {
    returnObjects: true,
  });

  return (
    <>
      {attendees.length > 0 && attendees.map((a, i) => renderGuestForm(a, i))}
      <Row>
        <Col
          md={12}
          className='mb-3'
        >
          <div className='form-check'>
            <label className='form-check-label'>
              <p className='text-justify'>
                {`Para participar do evento DS World SP que será realizado pela Dentsply Sirona (“DS”), a empresa INTEEGRA precisará coletar alguns de seus dados pessoais para fins de identificação e realização da compra dos ingressos, como nome, e-mail e telefone. Para melhor entendimento sobre como as empresas INTEEGRA e Dentsply Sirona tratam seus dados pessoais e quais são os seus direitos, convidamos você a acessar a Política de Privacidade da INTEEGRA disponível no `}
                <a
                  href={'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2024/bb65072b-c682-4081-9206-5266c7da0513.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2146667335&Signature=s8PaNPUW2g%2FqdeA6T42ZMwOheyo%3D'}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  {`link`}
                </a>
                {` e a Política de Privacidade da Dentsply Sirona através do `}
                <a
                  href={'https://www.dentsplysirona.com/pt-br/juridico/politica-de-privacidade.html'}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  {`link.`}
                </a>
              </p>
            </label>
          </div>
          <div className='form-check'>
            <label className='form-check-label'>
              <input
                id={`attendee-${0}-aceptTerms`}
                className='form-check-input'
                type='checkbox'
                checked={attendees[0]?.aceptTerms}
                onChange={(e) => {
                  fillAttendee('aceptTerms', !attendees[0]?.aceptTerms, 0);
                }}
              ></input>
              <p className='text-justify'>
                {`Autorizo o tratamento dos meus dados pessoais pela DS e pela INTEEGRA, empresa responsável pela plataforma de venda de ingressos, para participar do evento DS World SP a ser realizado pela DS *`}
              </p>
            </label>
          </div>
          <div className='form-check'>
            <label className='form-check-label'>
              <input
                id={`attendee-${0}-marketingTerms1`}
                className='form-check-input'
                type='checkbox'
                checked={attendees[0]?.marketingTerms1}
                onChange={(e) => {
                  fillAttendee('marketingTerms1', !attendees[0]?.marketingTerms1, 0);
                }}
              ></input>
              <p className='text-justify'>
                {`Autorizo a DS a enviar comunicações, por qualquer meio, sobre o evento DS World SP *`}
              </p>
            </label>
          </div>
          <div className='form-check'>
            <label className='form-check-label'>
              <input
                id={`attendee-${0}-marketingTerms2`}
                className='form-check-input'
                type='checkbox'
                checked={attendees[0]?.marketingTerms2}
                onChange={(e) => {
                  fillAttendee('marketingTerms2', !attendees[0]?.marketingTerms2, 0);
                }}
              ></input>
              <p className='text-justify'>
                {`Autorizo a DS a enviar comunicações sobre seus produtos e serviços *`}
              </p>
            </label>
          </div>

          <div className='form-check'>
            <input
              id={`attendee-${0}-cancelTerms`}
              className='form-check-input'
              type='checkbox'
              checked={attendees[0]?.cancelTerms}
              onChange={(e) => {
                fillAttendee('cancelTerms', !attendees[0]?.cancelTerms, 0);
              }}
            ></input>
            <label className='form-check-label'>
              <p className='text-justify'>
                {`Aceito os termos da `}
                <a
                  href={'https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2024/3eeb95d3-04bf-4887-be01-9111f6a76dac.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2146481685&Signature=y1NEvMqIdQ5zr2zx%2BZv5uS8kXr4%3D'}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  {`Política de Cancelamento`}
                </a>
                {` *`}
              </p>
            </label>
          </div>

          <div className='form-check'>
            <input
              id={`attendee-${0}-complianceTerms`}
              className='form-check-input'
              type='checkbox'
              checked={attendees[0]?.complianceTerms}
              onChange={(e) => {
                fillAttendee('complianceTerms', !attendees[0]?.complianceTerms, 0);
              }}
            ></input>
            <label className='form-check-label'>
              <p className='text-justify'>
                {`Termo de Ciência: Este ingresso inclui acesso a todas as sessões da Plenária Principal e Almoço volante para os dois dias do evento. Inclui também o custo de acesso à recepção com entretenimento após o término do primeiro dia de evento (avaliado em R$ 300 por pessoa). Não garantimos o acesso às salas de hands-on, ficando a cargo do comprador efetuar seu agendamento através de aplicação, a ser disponibilizada por e-mail até 7 dias antes do início do evento, e sujeito à disponibilidade de vagas. *`}
              </p>
            </label>
          </div>
        </Col>
      </Row>
    </>
  );

  function renderGuestForm(attendee, index) {
    return (
      <div key={index}>
        <h3 className='mb-3'>
          Informações do {length === 1 ? '' : `${index + 1}º`} participante
        </h3>
        <Row>
          <Col
            md={6}
            className='mb-3'
          >
            <label
              className='form-label'
              htmlFor={`attendee-${index}-name`}
            >
              <i className='bi bi-person me-2' />
              {t('attendee.fields.name')}
            </label>
            <input
              id={`attendee-${index}-name`}
              className='form-control'
              autoComplete='off'
              value={attendee.name}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = alphabetMask(value.toUpperCase());
                fillAttendee('name', newValue, index);
              }}
            />
          </Col>
          <Col
            md={6}
            className='mb-3'
          >
            <label
              className='form-label'
              htmlFor={`attendee-${index}-email`}
            >
              <i className='bi bi-envelope me-2'></i>
              {t('attendee.fields.email')}
            </label>
            <input
              type='email'
              id={`attendee-${index}-email`}
              className='form-control'
              name='email'
              autoComplete='off'
              value={attendee.email}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = emailMask(value);
                fillAttendee('email', newValue, index);
              }}
            // disabled={index === 0 ? true : false}
            />
          </Col>
        </Row>
        <Row>
          {/* <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-document`}
          >
            <i className='bi bi-123 me-2'></i>
            {t('attendee.fields.document')}
          </label>
          <input
            type='email'
            id={`attendee-${index}-document`}
            className='form-control'
            name='document'
            autoComplete='off'
            value={attendee.document}
            disabled={index === 0 && cpfLogin !== ''}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = cpfMask(value);
              fillAttendee('document', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.document);
              fillAttendee('document', newValue, index);
            }}
            onBlur={() => checkIfCpfIsRegistered(attendee.document, index)}
          />
          <label
            style={{ color: 'red' }}
            className='form-label'
            htmlFor={`attendee-${index}-document`}
          >
            {nextButtonControl.errorMessage}
          </label>
        </Col> */}
          <Col
            md={6}
            className='mb-3'
          >
            <label
              className='form-label'
              htmlFor={`attendee-${index}-cellPhone`}
            >
              <i className='bi bi-123 me-2'></i>
              {t('attendee.fields.cellPhone')}
            </label>
            <input
              type='email'
              id={`attendee-${index}-cellPhone`}
              className='form-control'
              name='cellPhone'
              autoComplete='off'
              value={attendee.cellPhone}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value;
                fillAttendee('cellPhone', newValue, index);
              }}
            />
            <label
              style={{ color: 'red' }}
              className='form-label'
              htmlFor={`attendee-${index}-cellPhone`}
            >
              {nextButtonControl.errorMessage}
            </label>
          </Col>
          <Col
            md={6}
            className='mb-3'
          >
            <label
              className='form-label'
              htmlFor={`attendee-${index}-badgename`}
            >
              <i className='bi bi-person-badge me-2'></i>
              {t('attendee.fields.badgename')}
            </label>
            <input
              id={`attendee-${index}-badgename`}
              className='form-control'
              autoComplete='off'
              value={attendee.badgename}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = badgeMask(value);
                fillAttendee('badgename', newValue, index);
              }}
            />
          </Col>
          <Col
            md={6}
            className='mb-3'
          >
            <label
              className='form-label'
              htmlFor={`attendee-${index}-speciality`}
            >
              <i className='bi bi-info-square me-2'></i>
              {t('attendee.fields.speciality.name')}
            </label>
            <select
              id={`attendee-${index}-speciality`}
              className='form-control'
              autoComplete='off'
              value={attendee.speciality}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value;
                fillAttendee('speciality', newValue, index);
              }}
            >
              <option value={''}>{t('attendee.fields.speciality.select')}</option>
              {specialitys.map((speciality, index) => (
                <option
                  key={index}
                  value={speciality.code}
                >
                  {speciality.name}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </div>
    )
  }
};
