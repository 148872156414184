import { useEffect, useState, useContext, useRef } from 'react';
import { isPixPaid } from '../../services/ispixpaid';
import { Row } from 'reactstrap';
export const PixQrCode = ({
  remainingTime,
  qrCode,
  qrCodeText,
  transactionId,
  setPaid,
  t,
}) => {
  // const { remainingTime } = useContext(TransactionContext);
  const defaultText = t('pix.fields.tocopy');
  const [textButton, setTextButton] = useState(defaultText);
  const buttonCopyRef = useRef(null);
  const fetchData = async () => {
    try {
      const response = await isPixPaid(transactionId);
      if (response?.success) {
        setPaid(response.success);
      }
    } catch (error) {}
  };
  useEffect(() => {
    const initialize = () => {
      const intervalId = setInterval(fetchData, 1000 * 5);
      const timeoutId = setTimeout(
        () => clearInterval(intervalId),
        1000 * 60 * 5,
      );
      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    };
    initialize();
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleCopy = () => {
    const tempInput = document.createElement('input');
    tempInput.value = qrCodeText;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setTextButton(t('pix.fields.copied'));

    setTimeout(() => {
      setTextButton(defaultText);
    }, 1300);
  };

  const handleImageLoad = () => {
    if (buttonCopyRef.current) {
      buttonCopyRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  return (
    <Row>
      <section className='d-flex flex-column justify-content-center align-items-center'>
        <h5>
          {remainingTime === 0 ? (
            <>{t('pix.fields.expired')}</>
          ) : (
            <>
              {t('pix.fields.expirein')} {formatTime(remainingTime)}
            </>
          )}
        </h5>
        <div className='d-flex flex-column align-items-center mb-3'>
          <img
            loading='lazy'
            className='w-50 mb-2 border'
            alt='Qr code para realizar o pagamento'
            onLoad={handleImageLoad}
            src={qrCode}
          />
          <button
            id='btn-copy-qr-code'
            className='btn btn-success w-50'
            onClick={handleCopy}
            ref={buttonCopyRef}
          >
            {textButton}
          </button>
        </div>
      </section>
    </Row>
  );
};
