import { Row, Col } from 'reactstrap';

import { PixImg, CreditCardImg } from '../../helpers/images';
import { PIX, CREDITCARD } from '../../helpers/constants';

export default function PaymentOption({
  handlePix,
  handleCreditCard,
  transaction,
  t,
}) {
  return (
    <>
      <Row className='ps-2 pe-2'>
        <Col
          md={6}
          className='d-flex flex-column justify-content-center align-items-center mb-3 border'
        >
          <h6 className='mb-3'>{t('pix.fields.title')}</h6>
          <img
            alt='Pix'
            src={PixImg}
            className='mb-1 h-25'
          />
          <input
            id='transaction-payment-type-pix'
            type='radio'
            className='form-check-input'
            value={transaction.paymentType}
            checked={transaction.paymentType === PIX}
            onChange={handlePix}
          />
        </Col>
        <Col
          md={6}
          className='d-flex flex-column justify-content-center align-items-center mb-3 border'
        >
          <h6 className='mb-3'>{t('cctoken.fields.title')}</h6>
          <img
            alt='CreditCard'
            src={CreditCardImg}
            className='mb-1 h-25'
          />
          <input
            id='transaction-payment-type-credit-card'
            type='radio'
            className='form-check-input'
            value={transaction.paymentType}
            checked={transaction.paymentType === CREDITCARD}
            onChange={handleCreditCard}
          />
        </Col>
      </Row>
    </>
  );
}
