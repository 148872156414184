import {
  isCVV,
  isCreditCardNumber,
  isEmpty,
  isEmail,
  isCPForCNPJ,
  isMobile,
  isCPF,
  isCNPJ,
} from '../helpers/validation';

export const useError = () => {
  const goToFieldWithError = (id) => {
    const element = document.getElementById(id);
    let offset = 150;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
    element.focus();
  };

  const attendeeError = {
    name: (attendee) => !isEmpty(attendee.name),
    email: (attendee) => isEmail(attendee.email),
    cellPhone: (attendee) => !isEmpty(attendee.cellPhone),
    badgename: (attendee) => !isEmpty(attendee.badgename),
    speciality: (attendee) => !isEmpty(attendee.speciality),
    aceptTerms: (attendee) => attendee.aceptTerms,
    marketingTerms1: (attendee) => attendee.marketingTerms1,
    marketingTerms2: (attendee) => attendee.marketingTerms2,
    cancelTerms: (attendee) => attendee.cancelTerms,
    complianceTerms: (attendee) => attendee.complianceTerms,
  };

  const cctokenError = {
    number: (cctoken) => isCreditCardNumber(cctoken.number),
    name: (cctoken) => !isEmpty(cctoken.name),
    country: (cctoken) => !isEmpty(cctoken.country),
    expireAt: (cctoken) => !isEmpty(cctoken.expireAt),
    code: (cctoken) => isCVV(cctoken.code),
  };

  const customerError = {
    name: (customer) => !isEmpty(customer.name),
    document: (customer) => isCPForCNPJ(customer.document),
    email: (customer) => isEmail(customer.email),
    address: (customer) => !isEmpty(customer.address),
  };
  return {
    attendeeError,
    customerError,
    cctokenError,
    goToFieldWithError,
  };
};
