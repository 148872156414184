import { cnpj, cpf } from 'cpf-cnpj-validator';

const isEmpty = (value) => value.trim() === '';

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const isEmail = (value) => emailRegex.test(value);

const mobileRegex = /^\(\d{2}\) 9\d{4}-\d{4}$/;
const isMobile = (value) => {
  return mobileRegex.test(value);
};

const zipCodeRegex = /^\d{5}-\d{3}$/;
const isZipCode = (value) => zipCodeRegex.test(value);

const cvvCodeRegex = /^\d{3,4}$/;
const isCVV = (value) => cvvCodeRegex.test(value);

const isCreditCardNumber = (value) => {
  if ('' === value || undefined === value || null === value) return false;
  const test = '4111111111111111';

  if (test === value) return true;

  value = value.replace(/\D/g, '');
  var nCheck = 0,
    nDigit = 0,
    bEven = false;

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10);

    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
};

const isCPF = (document) => {
  const currentDocument = document.replace(/\D/g, '');
  return cpf.isValid(currentDocument);
};
const isCNPJ = (document) => {
  const currentDocument = document.replace(/\D/g, '');
  return cnpj.isValid(currentDocument);
};
const isCPForCNPJ = (document) => {
  const currentDocument = document.replace(/\D/g, '');
  const validated = cpf.isValid(currentDocument)
    ? true
    : cnpj.isValid(currentDocument)
    ? true
    : false;
  return validated;
};

export {
  isEmpty,
  isEmail,
  isMobile,
  isCreditCardNumber,
  isCVV,
  isCPForCNPJ,
  isZipCode,
  isCPF,
  isCNPJ,
};
