import Zendesk from "react-zendesk";

export default function ZendeskComponent() {
  const _departmentName = [""];
  const _buttonLabelOnline = 'nda#Chat';
  const _buttonLabelOffline = 'nda#Suporte';
  const _chatTitle = 'nda#Help Center';
  const setting = {
    contactOptions: {
      enabled: false,
      contactButton: {
        '*': 'Get in Touch'
      },
      // The Chat Label on the Contact Options window
      chatLabelOnline: {
        '*': 'Enjoy Live Chat'
      },
      chatLabelOffline: {
        '*': 'Chat is Offline'
      },
      // The Contact Form Label
      contactFormLabel: {
        '*': 'Leave us a message'
      }
    },
    // The Widget Color
    color: {
      theme: '#000'
    },
    launcher: {
      // The Web Widget button title (HC/Contact Form are On)
      label: {
        '*': _buttonLabelOffline.replace("nda#", "")
      },
      // The Web Widget button title (HC is Off)
      chatLabel: {
        '*': _buttonLabelOnline.replace("nda#", "")
      },
    },
    helpCenter: {
      // Sets the title of the Help Center Window
      title: {
        '*': 'ACME SUPPORT'
      }
    },
    contactForm: {
      title: {
        '*': _chatTitle.replace("nda#", "")
      }
    },
    chat: {
      title: {
        '*': _chatTitle.replace("nda#", "")
      },
      departments: {
        // enabled: ['']
        enabled: _departmentName,
        //select: _departmentName[0].el
      },
    },
    talk: {
      title: {
        '*': _chatTitle.replace("nda#", "")
      }
    },
  }

  return <Zendesk defer zendeskKey={'90962788-7bd8-49db-86d0-67025eb02492'} {...setting} onLoaded={() => console.log('Carregado')} />
};
