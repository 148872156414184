import { useState, useEffect, useContext } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Header from '../../components/header';
import { getTickets } from '../../services/product';
import { Information } from '../../components/information';
import { useNavigate } from 'react-router-dom';
import { TransactionContext } from '../../contexts/TransactionContext';
import { useTranslation } from 'react-i18next';
import { Tickets } from '../../components/tickets';
import Event from '../../components/event';
import { useError } from '../../hooks/useError';
import { Alert } from '../../components/modal';
import Zendesk from '../../components/zendesk';

export const Cart = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { setCart, newTickets, maxCount } = useContext(TransactionContext);
  const [tickets, setTickets] = useState([]);

  const { goToFieldWithError } = useError();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    goToFieldWithError('btn-buy');
  };

  const modal = (
    <Alert
      isOpen={open}
      toggle={closeModal}
      message={message}
    />
  );

  const updateTicketCount = (value, index, ticket) =>
    setTickets((prevState) => {
      const newState = [...prevState];
      const currentCount = newState[index].count;
      let newCount = currentCount + value;
      if (ticket.purchaseMax <= newCount) {
        newCount = ticket.purchaseMax;
      }
      if (
        newState[index] === undefined ||
        newCount > maxCount ||
        newCount < 0
      ) {
        return newState;
      }
      newState[index] = { ...newState[index], count: newCount };
      return newState;
    });

  const handleAddTicket = (e, index, ticket) => {
    updateTicketCount(1, index, ticket);
  };

  const handleRemoveTicket = (e, index, ticket) => {
    updateTicketCount(-1, index, ticket);
  };

  const handleBuy = (e) => {
    e.preventDefault();
    const ticektsToBuy = tickets.filter((ticket) => ticket.count > 0);
    if (ticektsToBuy.length > 0) {
      setCart(ticektsToBuy);
      if (ticektsToBuy[0].tag.includes('pacote_')) {
        navigate('/pagamento_grupo');
      } else {
        navigate('/pagamento');
      }
    } else {
      setMessage(t('cart.errors.notselected'));
      openModal();
    }
  };

  const handleBack = (e) => {
    navigate('/');
  };

  useEffect(() => {
    if (!newTickets || newTickets.length === 0) {
      navigate('/');
    }
    const fetchTickets = async () => {
      // const response = await getTickets(login);
      // const newTickets = response.map((ticket) => ({
      //   ...ticket,
      //   count: 0,
      // }));
      setTickets(newTickets);
    };
    fetchTickets();
  }, []);

  return (
    <>
      {modal}
      <section className='d-flex flex-column justify-content-center align-items-center w-100'>
        <Header />
        <Card>
          <CardBody>
            <Row>
              <Col
                md={6}
                className='d-flex flex-column gap-1'
              >
                <section className='mb-3'>
                  <Event t={t} />
                  <Information t={t} />
                </section>
              </Col>
              <Col
                md={6}
                className='d-flex flex-column gap-1'
              >
                <Tickets
                  handleAdd={handleAddTicket}
                  handleRemove={handleRemoveTicket}
                  handleBuy={handleBuy}
                  handleBack={handleBack}
                  tickets={tickets}
                  t={t}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </section>
      <Zendesk />
    </>
  );
};
