const PIX = 'pix';
const CREDITCARD = 'credit_card';
const FREE = 'free';
const EVENTID = process.env.REACT_APP_EVENT_ID;
const ACCOUNTID = process.env.REACT_APP_ACCOUNT_ID;
const TESTMODE = process.env.REACT_APP_TEST_MODE === '1' ? true : false;
const DEV = process.env.REACT_APP_ENV === 'dev';
const PIXEXPIRETIME = 300;

export {
  PIX,
  CREDITCARD,
  FREE,
  EVENTID,
  ACCOUNTID,
  TESTMODE,
  DEV,
  PIXEXPIRETIME,
};
